/** @jsx jsx */
import { css, jsx } from "@emotion/core";

type Props = {
  contact: {
    socialLink: string;
    socialIcon: string;
  };
};

const ContactIcon = ({ contact }: Props) => (
  <a
    css={styles}
    href={contact.socialLink}
    target="_blank"
    rel="noopener noreferrer"
  >
    <i className={contact.socialIcon}></i>
  </a>
);

const styles = css`
  color: #fff;
  i {
    font-size: 28px;
    transition: color 900ms;
    &:hover {
      color: #ca40ba;
    }
  }
  @media (max-width: 415px) {
    i {
      font-size: 22px;
    }
  }
`;

export default ContactIcon;
