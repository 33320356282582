/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

const Container = ({ children }: Props) => (
  <div css={styles} className="container">
    {children}
  </div>
);

const styles = css`
  width: 100%;
  max-width: 1260px;
  margin: 0 auto;
  @media (max-width: 1660px) {
    max-width: 90%;
  }
`;

export default Container;
