/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import ProjectsDataInterface from "../../interface/ProjectsDataInterface";
import Container from "../GlobalComponents/Container";
import ProjectsCard from "./ProjectCard";
import ProjectOneImg from "../../Images/projectOne.png";
import ProjectTwoImg from "../../Images/projectTwo.png";
import ProjectThreeImg from "../../Images/projectThree.png";
import ProjectFourImg from "../../Images/projectFour.png";
import ProjectFiveImg from "../../Images/projectFive.png";
import ProjectSixImg from "../../Images/projectSix.png";

const projectsData: ProjectsDataInterface[] = [
  {
    img: ProjectOneImg,
    title: "Twitch Panels App",
    desc: "Make custom twitch panels",
    privateRepo: true,
    link: "https://laughing-pike-162008.netlify.app/",
  },
  {
    img: ProjectTwoImg,
    title: "Custom Button App",
    desc: "Make custom button",
    privateRepo: true,
    link: "https://custombutton.xyz/",
  },
  {
    img: ProjectThreeImg,
    title: "Learn to code App",
    desc: "Learn basic css",
    privateRepo: true,
    link: "https://wonderful-jepsen-e0a68e.netlify.app/",
  },
  {
    img: ProjectFourImg,
    title: "Hosting website",
    desc: "Project about hosting",
    privateRepo: false,
    link: "https://gracious-lichterman-9d74b4.netlify.app/",
    demo: "https://github.com/h3h394/React-Hosting-Website",
  },
  {
    img: ProjectFiveImg,
    title: "Conference website",
    desc: "Project about conference",
    privateRepo: false,
    link: "https://condescending-varahamihira-cea1da.netlify.app/",
    demo: "https://github.com/h3h394/React-Conference-Website",
  },
  {
    img: ProjectSixImg,
    title: "Gym website",
    desc: "Project about gym",
    privateRepo: false,
    link: "https://laughing-pasteur-fe8b4b.netlify.app/",
    demo: "https://github.com/h3h394/React-Gym-Website2",
  },
];

const Projects = () => (
  <section css={styles} className="projects">
    <Container>
      {projectsData.map((project, index) => (
        <ProjectsCard key={index} project={project} />
      ))}
    </Container>
  </section>
);

const styles = css`
  width: 100%;
  min-height: 100vh;
  display: flex;
  .container {
    margin: auto;
    overflow: hidden;
    max-width: 1040px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    overflow-y: scroll;
    height: 520px;
    max-height: 520px;
    width: 100%;
    &::-webkit-scrollbar {
      width: 0;
    }
  }
  @media (max-width: 1120px) {
    .container {
      justify-content: center;
      height: 600px;
      max-height: 600px;
    }
  }
  @media (max-width: 915px) {
    width: calc(100% - 80px);
    margin-left: auto;
  }
  @media (max-width: 1660px) {
    .container {
      max-width: 90%;
    }
  }
`;

export default Projects;
