/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { useState } from "react";
import NavMenu from "./NavMenu";

const Nav = () => {
  const [navWidth, setNavWidth] = useState(false);

  return (
    <nav css={styles} className={navWidth ? "navWidth" : ""}>
      <i
        onClick={() => setNavWidth(!navWidth)}
        className={navWidth ? "fas fa-braille" : "fas fa-times"}
      ></i>
      <NavMenu navWidth={navWidth} />
    </nav>
  );
};

const styles = css`
  width: 100%;
  max-width: 340px;
  padding: 120px 0 0 0;
  min-height: 100vh;
  background: linear-gradient(0deg, #3a4fbc, #180a58);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 20;
  transition: max-width 800ms ease-in-out;
  border-radius: 0 0 250px 0;
  overflow: hidden;
  &.navWidth {
    max-width: 80px;
    padding: 0;
    justify-content: center;
    > i {
      top: 30px;
      left: 50%;
      right: 0;
      transform: translateX(-50%);
      transition: all 1000ms ease-in-out;
      opacity: 1;
    }
    .navMenu {
      a {
        i {
          opacity: 1;
        }
      }
    }
  }
  > i {
    color: #fff;
    cursor: pointer;
    font-size: 30px;
    position: absolute;
    top: 20px;
    right: 20px;
    opacity: 0.7;
    transition: opacity 700ms ease-in-out;
    &:hover {
      opacity: 1;
    }
  }
  @media (max-width: 915px) {
    width: 100%;
    padding: 0;
    max-height: 100vh;
    position: absolute;
    left: 0;
    max-width: 80px;
    justify-content: center;
    > i {
      display: none;
    }
  }
`;

export default Nav;
