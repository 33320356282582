/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import Container from "../GlobalComponents/Container";
import AboutBg from "./AboutBg";
import AboutInfo from "./AboutInfo";

const About = () => (
  <section css={styles} className="about">
    <Container>
      <AboutBg />
      <AboutInfo />
    </Container>
  </section>
);

const styles = css`
  width: 100%;
  min-height: 100vh;
  display: flex;
  position: relative;
  overflow: hidden;
  padding: 40px 0;
  .container {
    margin: auto;
    display: flex;
    justify-content: space-between;
  }
  @media (max-width: 575px) {
    padding: 20px 0;
  }
  @media (max-width: 915px) {
    width: calc(100% - 80px);
    margin-left: auto;
  }
  @media (max-width: 1270px) {
    .container {
      flex-direction: column-reverse;
    }
  }
`;

export default About;
