/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import Container from "../GlobalComponents/Container";
import HeroInfo from "./HeroInfo";
import HeroBg from "../../Images/heroBg.jpg";

const Hero = () => (
  <section css={styles} className="hero">
    <Container>
      <HeroInfo />
    </Container>
  </section>
);

const styles = css`
  width: 100%;
  min-height: 100vh;
  background: url("${HeroBg}") no-repeat top left/cover;
  @media (max-width: 915px) {
    width: calc(100% - 80px);
    margin-left: auto;
  }
`;

export default Hero;
