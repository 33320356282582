/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import ProfileBg from "../../Images/profile.jpeg";

const AboutBg = () => <div css={styles} className="aboutBg"></div>;

const styles = css`
  width: 100%;
  max-width: 400px;
  height: 600px;
  border-radius: 22px 0px 22px 0px;
  background: url("${ProfileBg}") no-repeat center/cover;
  @media (max-width: 575px) {
    margin: 10px 0 0 0;
    max-width: 280px;
    height: 360px;
    background-size: 100% 100%;
  }
  @media (min-width: 576px) and (max-width: 1270px) {
    max-width: 370px;
    height: 460px;
    background-size: 100% 100%;
  }
  @media (min-width: 1271px) and (max-width: 1660px) {
    max-width: 480px;
    background-size: 100% 100%;
  }
`;

export default AboutBg;
