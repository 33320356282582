/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import Typist from "react-typist";

const HeroInfo = () => (
  <div css={styles} className="heroInfo">
    <Typist cursor={{ show: false }}>
      <Typist.Delay ms={500} />
      <span>&lt;body&gt;</span>
    </Typist>
    <div className="description">
      <Typist cursor={{ show: false }}>
        <Typist.Delay ms={1000} />
        <span>&lt;h1&gt;</span>
        <h2>
          Hi
          <br /> I'm Miloš
          <br /> web developer.
        </h2>
        <span>&lt;/h1&gt;</span>
      </Typist>
    </div>
    <Typist cursor={{ show: false }}>
      <Typist.Delay ms={3800} />
      <span>&lt;/body&gt;</span>
    </Typist>
  </div>
);

const styles = css`
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  padding: 120px 0;
  .description {
    min-height: 248px;
  }
  h2 {
    font-size: 60px;
    line-height: 1;
    text-shadow: 4px 4px 1px #ca40ba;
    padding-left: 12px;
  }
  span {
    display: block;
    font-size: 21px;
    color: #ca40ba;
    font-weight: 800;
    text-shadow: none;
    margin: 3px 0;
  }
  @media (max-width: 415px) {
    h2 {
      font-size: 34px;
      span {
        margin: 24px 0 0 0;
      }
    }
  }
  @media (min-width: 416px) and (max-width: 915px) {
    padding: 60px 0;
    h2 {
      font-size: 50px;
      span {
        margin: 24px 0 0 0;
      }
    }
  }
  @media (min-height: 567px) and (max-height: 569px) {
    h2 {
      font-size: 26px;
    }
  }
`;

export default HeroInfo;
