/** @jsx jsx */
import { css, jsx } from "@emotion/core";

const ContactForm = () => {
  return (
    <div css={styles} className="contactFormContainer">
      <form name="contact" method="post" action="/contact">
        <input type="hidden" name="form-name" value="contact" />
        <input required type="text" name="name" placeholder="Name" />
        <input required type="email" name="email" placeholder="Email" />
        <textarea
          required
          name="message"
          placeholder="I have an idea for a project..."
        ></textarea>
        <div className="btnContainer">
          <button type="submit">Send</button>
        </div>
      </form>
    </div>
  );
};

const styles = css`
  display: flex;
  form {
    margin: auto;
    padding: 0 40px;
    border-left: 10px solid #ca40ba;
    input {
      width: 100%;
      padding: 12px;
      border: none;
      border-bottom: 1px solid #ca40ba;
      outline: none;
      background: transparent;
      margin-bottom: 24px;
      color: #fff;
      &::placeholder {
        color: #fff;
        letter-spacing: 0.8px;
      }
    }
    textarea {
      width: 100%;
      border: none;
      border-bottom: 1px solid #ca40ba;
      outline: none;
      padding: 12px;
      height: 150px;
      resize: none;
      background: transparent;
      margin-bottom: 24px;
      color: #fff;
      &::placeholder {
        color: #fff;
        letter-spacing: 0.8px;
      }
    }
    .btnContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      button {
        border: none;
        outline: none;
        background: #ca40ba;
        color: #fff;
        padding: 10px 34px;
        align-self: flex-end;
        border-radius: 4px;
        cursor: pointer;
        transition: background 800ms ease-in-out;
        font-weight: 400;
        letter-spacing: 1.3px;
        &:hover {
          background: #ca40ba;
        }
      }
    }
  }
  @media (max-width: 1260px) {
    form {
      padding: 20px 0;
      border-top: 10px solid #bb14c5;
      border-left: none;
    }
  }
  @media (min-height: 567px) and (max-height: 569px) {
    form {
      input,
      textarea {
        &::placeholder {
          font-size: 13px;
        }
      }
      textarea {
        height: 100px;
      }
    }
  }
`;

export default ContactForm;
