/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import Container from "../GlobalComponents/Container";
import ContactBg from "./ContactBg";
import ContactForm from "./ContactForm";

const Contact = () => (
  <section css={styles} className="contact">
    <Container>
      <ContactBg />
      <ContactForm />
    </Container>
  </section>
);

const styles = css`
  width: 100%;
  min-height: 100vh;
  display: flex;
  .container {
    margin: auto;
    display: flex;
  }
  @media (max-width: 915px) {
    width: calc(100% - 80px);
    margin-left: auto;
  }
  @media (max-width: 1260px) {
    .container {
      flex-direction: column;
      align-items: center;
    }
  }
`;

export default Contact;
