/** @jsx jsx */
import { css, jsx } from "@emotion/core";

const technologiesData = [
  "Html5",
  "Css3",
  "Sass",
  "Javascript",
  "React",
  "Typescript",
];

const AboutInfo = () => {
  return (
    <div css={styles} className="aboutInfo">
      <div className="infoContainer">
        <h1>
          I'm a 26 year-old front-end Web Developer from Belgrade, Serbia. I
          have a passion for creating fast loading, dynamic, and responsive
          websites using the latest technologies available.
        </h1>
        <p>Technologies & Tools that I use:</p>
        <div className="technologies">
          {technologiesData.map((tech, index) => (
            <p key={index}>
              <span></span> {tech}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

const styles = css`
  width: 100%;
  max-width: 800px;
  .infoContainer {
    width: 100%;
    padding: 20px 30px;
    color: #fff;
    > p {
      margin: 14px 0 0 0;
    }
    .technologies {
      padding: 14px 0 0 0;
      p {
        display: flex;
        align-items: center;
        padding: 6px 0;
        span {
          width: 6px;
          height: 6px;
          background: #fff;
          border-radius: 50%;
          margin-right: 10px;
        }
      }
    }
  }
  @media (max-width: 575px) {
    .infoContainer {
      padding: 0;
      h1 {
        font-size: 15px;
      }
      > p {
        font-size: 14px;
      }
      .technologies {
        padding: 10px 0 0 0;
        p {
          font-size: 14px;
          span {
            width: 5px;
            height: 5px;
          }
        }
      }
    }
  }
  @media (min-width: 576px) and (max-width: 1270px) {
    .infoContainer {
      padding: 20px 0;
      h1 {
        font-size: 18px;
      }
    }
  }
  @media (min-width: 1271px) and (max-width: 1660px) {
    max-width: 62%;
    .infoContainer {
      padding: 20px 0 20px 20px;
      h1 {
        font-size: 20px;
      }
    }
  }
`;

export default AboutInfo;
