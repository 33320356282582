/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { NavLink } from "react-router-dom";
import NavMenuInterface from "../../interface/NavMenuInterface";
import NavRow from "./NavRow";

const navMenuData: NavMenuInterface[] = [
  {
    link: "/",
    title: "Home",
    icon: "fas fa-home",
  },
  {
    link: "/projects",
    title: "Projects",
    icon: "fas fa-briefcase",
  },
  {
    link: "/about",
    title: "About",
    icon: "fas fa-user",
  },
  {
    link: "/contact",
    title: "Contact",
    icon: "fas fa-envelope",
  },
];

type Props = {
  navWidth: boolean;
};

const NavMenu = ({ navWidth }: Props) => (
  <div css={styles} className="navMenu">
    {navMenuData.map((navItem, index) => (
      <NavLink key={index} exact to={navItem.link} activeClassName="isActive">
        <NavRow navWidth={navWidth} title={navItem.title} icon={navItem.icon} />
      </NavLink>
    ))}
  </div>
);

const styles = css`
  a {
    text-decoration: none;
    &.isActive {
      .navRow {
        background: rgba(0, 0, 0, 0.15);
        border-left: 5px solid #ca40ba;
      }
    }
  }
`;

export default NavMenu;
