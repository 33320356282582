/** @jsx jsx */
import { css, jsx } from "@emotion/core";

type Props = {
  title: string;
  icon: string;
  navWidth: boolean;
};

const NavRow = ({ icon, title, navWidth }: Props) => (
  <div css={styles} className={(navWidth ? "hiddenTitle" : "") + " navRow"}>
    <i className={icon}></i>
    <p>{title}</p>
  </div>
);

const styles = css`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 27px;
  transition: background 900ms, border 500ms;
  border-left: 5px solid transparent;
  color: #fff;
  position: relative;
  p {
    opacity: 1;
    transition: opacity 1200ms ease-in-out;
    padding-left: 20px;
    line-height: 1;
    text-shadow: 4px 4px 1px #ca40ba;
    font-size: 34px;
    font-weight: 700;
    user-select: none;
  }
  i {
    font-size: 20px;
    opacity: 0;
  }
  &.hiddenTitle {
    padding: 36px 18px;
    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    p {
      opacity: 0;
      padding-left: 180px;
    }
  }
  @media (max-width: 915px) {
    justify-content: center;
    padding: 30px 18px;
    p {
      display: none;
    }
    i {
      font-size: 22px;
      opacity: 1;
    }
  }
`;

export default NavRow;
