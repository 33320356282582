/** @jsx jsx */
import { css, jsx } from "@emotion/core";

type Props = {
  project: {
    img: string;
    title: string;
    desc: string;
    privateRepo: boolean;
    link: string;
    demo?: string;
  };
};

const ProjectCard = ({ project }: Props) => (
  <div css={styles} className="projectCard">
    <div
      style={{ background: `url(${project.img}) no-repeat center/cover` }}
      className="projectCardBg"
    ></div>
    <div className="cardWrapper">
      <div className="projectCardInfo">
        <h3>{project.title}</h3>
        <p>{project.desc}</p>
      </div>
      <div className="btnContainer">
        <a
          href={project.link}
          target="_blank noopener noreferrer"
          className="btn"
        >
          View Live
        </a>
        <a
          style={{ cursor: project.privateRepo ? "not-allowed" : "pointer" }}
          href={project.demo}
          target="_blank noopener noreferrer"
          className="btn"
        >
          View Code
        </a>
      </div>
    </div>
  </div>
);

const styles = css`
  width: 100%;
  max-width: 320px;
  background: #fff;
  overflow: hidden;
  position: relative;
  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3) {
    margin: 0 0 36px 0;
  }
  .projectCardBg {
    position: relative;
    width: 100%;
    min-height: 200px;
  }
  .cardWrapper {
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .projectCardInfo,
    .btnContainer {
      width: 100%;
      max-width: 85%;
    }
    .projectCardInfo {
      h3 {
        color: #3a4fbc;
        font-size: 24px;
        line-height: 1;
      }
      p {
        margin: 6px 0 10px 0;
        color: #b4b4bf;
      }
    }
    .btnContainer {
      display: flex;
      align-items: center;
      .btn {
        width: 120px;
        padding: 10px 0;
        text-align: center;
        border-radius: 4px;
        text-decoration: none;
        display: inline-block;
        color: #fff;
        background: #323fa5;
        transition: background 400ms ease-in-out;
        &:first-of-type {
          margin-right: 8px;
        }
        &:hover {
          background: #29327b;
        }
      }
    }
  }

  @media (max-width: 1120px) {
    max-width: 500px;
    &:not(:last-of-type) {
      margin-bottom: 28px;
    }
  }
  @media (min-width: 1121px) and (max-width: 1660px) {
    max-width: 48%;
    .projectCardBg {
      min-height: 240px;
    }
  }
`;

export default ProjectCard;
