/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import ContactIconsInterface from "../../interface/ContactIconsInterface";
import HeroBg from "../../Images/heroBg.jpg";
import ContactIcon from "./ContactIcon";

const contactIcons: ContactIconsInterface[] = [
  {
    socialLink: "https://www.youtube.com/h3webdevtuts",
    socialIcon: "fab fa-youtube",
  },
  {
    socialLink: "https://github.com/h3h394",
    socialIcon: "fab fa-github",
  },
  {
    socialLink: "https://discord.gg/FNUSuR5Cww",
    socialIcon: "fab fa-discord",
  },
  {
    socialLink: "https://twitter.com/h3webdeveloper",
    socialIcon: "fab fa-twitter",
  },
];

const ContactBg = () => (
  <div css={styles} className="contactBg">
    <div className="connect">
      <h2>Connect</h2>
      <div className="iconsContainer">
        {contactIcons.map((contact) => (
          <ContactIcon contact={contact} />
        ))}
      </div>
    </div>
  </div>
);

const styles = css`
  width: 100%;
  max-width: 760px;
  height: 520px;
  padding: 40px;
  position: relative;
  background: url("${HeroBg}") no-repeat top left/cover;
  display: flex;
  align-items: flex-end;
  .connect {
    z-index: 2;
    position: relative;
    width: 100%;
    max-width: 240px;
    h2 {
      color: #fff;
      font-size: 35px;
      line-height: 1;
      letter-spacing: 2px;
      padding: 8px 0;
      position: relative;
      transition: color ease 0.3s;
      text-shadow: 4px 4px 1px #ca40ba;
      cursor: pointer;
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 9%;
        left: 0;
        bottom: 0;
        background: #ca40ba;
        transition: all ease 0.3s;
      }
      &:hover {
        &::after {
          height: 42%;
          z-index: -1;
        }
      }
    }
    .iconsContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 30px 0 0 0;
    }
  }
  @media (max-width: 415px) {
    height: 190px;
    padding: 20px 40px 20px 18px;
    .connect {
      h2 {
        font-size: 24px;
      }
      .iconsContainer {
        padding: 10px 0 0 0;
      }
    }
  }
  @media (min-width: 416px) and (max-width: 915px) {
    height: 340px;
  }
  @media (min-width: 916px) and (max-width: 1260px) {
    height: 400px;
  }
`;

export default ContactBg;
